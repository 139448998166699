<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Delete items</h3>

	<p>A System Admin, System Editor, Framework Admin, and Framework Editor have the ability and permissions to edit and delete items within a framework.
		<ol>
			<li>Choose the <nobr><v-icon small>fas fa-edit</v-icon></nobr> <strong>EDIT</strong>icon at the top of the framework.</li>
			<li>Click on any item in the framework and select the <strong>edit</strong> icon. </li>
			<li>Choose <nobr><v-icon small>fa-regular fa-trash-can</v-icon></nobr> <strong>DELETE THIS ITEM</strong> from the edit menu. </li>
		</ol>	
	</p>

	<p>The following pop up will appear:
		<br>
		<img srcset="/docimages/delete_item.png 1.3x" class="k-help-img block">
		<ul>
			<li>Either choose <strong>CANCEL</strong> or <strong>DELETE</strong></li>
		</ul>
	</p>

	<p>NOTE: The Framework Update Report will show the deleted item and allow a System Admin, System Editor, Framework Admin, and Framework Editor to restore any deleted items from an archived version of the framework. Learn more about Framework Archives <span v-html="link('archives','here')"></span></p>




</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>